<template>
     <div  v-if="showPlayer">
          <video id="videoElement" width="80%" height="500px" controls>
            <source :src="videoURL">
          </video>
        </div>
        <div  style="height: 85vh;display: flex; justify-content: center; align-items: center;"  v-else>
           <h2>Invalid video url</h2>
        </div>
</template>

<script>
    export default {
        props: {
            videoURL: String,
            showPlayer: Boolean
        }
    }
</script>