<template>
  <div id="app">
    <div v-if="!isLoading">
      <h3
        v-if="!isSuccess"
        style="
          height: 85vh;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        Something went wrong
      </h3>
      <div v-else>
        <VideoPreview
          :show-player="true"
          :videoURL="fileURL"
          v-if="data.filetype === 'video'"
        />
        <PDFViewer :url="fileURL" v-if="data.filetype === 'pdf'" />
        <FileViewer
          :url="fileURL"
          :file-name="fileName"
          v-if="data.filetype === 'file'"
        />
        <ImageViewer :url="fileURL" v-if="data.filetype === 'img'" />
      </div>
    </div>
    <div
      v-else
      style="
        height: 85vh;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <LoadSvg :height="30" :width="30" />
    </div>
  </div>
</template>

<script>
import CryptoJS from "./../node_modules/crypto-js";
import firebase from "../firebase";
import FileViewer from "./FileViewer.vue";
import ImageViewer from "./ImageViewer.vue";
import LoadSvg from "./LoadSvg.vue";
import PDFViewer from "./PDFViewer.vue";
import VideoPreview from "./VideoPreview.vue";
export default {
  components: { LoadSvg, VideoPreview, PDFViewer, FileViewer, ImageViewer },
  name: "App",
  data() {
    return {
      isLoading: true,
      data: null,
      fileURL: null,
      fileName: "",
      isSuccess: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    valid(vl) {
      return vl !== undefined && vl !== null && vl !== "";
    },

    processingQuqeyParams(queryParamsObj, pairQuery) {
      const handleKeys = {
        ftype: "filetype",
        d: "divisionId",
        fn: "filename",
        fl: "foldername",
        fmt: "fileextension",
        "widget-event": "widget-event",
        "widget-event-data": "widget-event-data",
        sid: "sessionId",
        version: 'version',
        bclient: 'bclient'
      };

      if (Object.keys(queryParamsObj).length === 0) {
        let newQueryParamsObj = {};

        Object.keys(handleKeys).forEach((key) => {
          newQueryParamsObj[handleKeys[key]] = "";
        });

        return newQueryParamsObj;
      }

      let updatedQueryParamsObj = { ...queryParamsObj };

      if (this.valid(pairQuery)) {
        const [key, value] = pairQuery.split("=");

        if (this.valid(handleKeys[key])) {
          updatedQueryParamsObj[handleKeys[key]] = decodeURIComponent(value);
        }
      }

      return updatedQueryParamsObj;
    },

   async getQueryParamsFromURL() {
      let queryParams = this.processingQuqeyParams({}, null);
      const url = window.location.href;
      const queryString = url.split("?")[1];

      if (queryString) {
        const pairs = queryString.split("&");
        pairs.forEach((pair) => {
          queryParams = this.processingQuqeyParams(queryParams, pair);
        });
      }
      else {
        const parts = url.split('/');
        const dynamicParameter = parts[parts.length - 1];

        if(this.valid(dynamicParameter)) {
        const urlFromStorage = await  firebase.firestore().doc(`shortener/${dynamicParameter}`).get().then(snap => {
            const doc = snap.data();
            let urlStorage = ''

            if(this.valid(doc) && doc.url) {
              urlStorage = doc.url;
            }

            return urlStorage;
          })

          if(urlFromStorage) {
            const queryString = urlFromStorage.split("?")[1];

            if (queryString) {
              const pairs = queryString.split("&");
              pairs.forEach((pair) => {
                queryParams = this.processingQuqeyParams(queryParams, pair);
              });
            }
          }

        }

      }

      return queryParams;
    },

    generateReferenceToFile() {
      let reference = "";

      if(!this.data.version) {
        if (this.valid(this.data.foldername)) {
          reference = `${this.data.foldername}/`;
        }

        if (this.valid(this.data.divisionId)) {
          reference += `${this.data.divisionId}/`;
        }

        if (this.valid(this.data.filename)) {
          reference += `${this.data.filename}.${this.data.fileextension}`;
        }
      }
      else {
        //version 2
        reference = `${this.data.bclient}/customer_attachments/${this.data.filename}`;
      }


      return reference;
    },

    async downloadFileFromStorageAndGetFileURL(filePath) {
      try {
        const fileURL = await firebase.storage().ref(filePath).getDownloadURL();
        return [fileURL, true];
      } catch (e) {
        return [null, false];
      }
    },

    async requestForCheckIP() {
      return fetch(
        `https://us-central1-car-appointment-fsqahl.cloudfunctions.net/isusercheck/iamempl`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((json) => json.iamempl || "")
        .catch(() => "");
    },

    isAgent() {
      return this.getCookies().iamempl;
    },

    getCookies() {
      const REGEXP = /([\w.]+)\s*=\s*(?:"((?:\\"|[^"])*)"|(.*?))\s*(?:[;,]|$)/g;
      let cookies = {};
      let match;
      while ((match = REGEXP.exec(document.cookie)) !== null) {
        let value = match[2] || match[3];
        cookies[match[1]] = decodeURIComponent(value);
      }

      return {
        iamempl: this.valid(cookies.iamempl)
          ? CryptoJS.AES.decrypt(cookies.iamempl, "cryptopagefile").toString(
              CryptoJS.enc.Utf8
            )
          : "",
      };
    },

    setck(t) {
      return CryptoJS.AES.encrypt(t, "cryptopagefile").toString();
    },
    setCookie({ key, value }) {
      document.cookie = `${key}=${this.setck(value)}; path=/; max-age=31536000`;
    },

    async checkIP() {
      let iamemp = this.isAgent();
      let timecheck = {
        1: 1000 * 60 * 60 * 2,
        2: 1000 * 60 * 60 * 4,
      };

      if (iamemp) {
        let split = iamemp.split(":");
        let id = split[0];

        if (id && id !== "none") {
          return true;
        }
        if (id && id === "none") {
          let lasttime = (split[1] && Number(split[1])) || null;
          let attemp = (split[2] && Number(split[2])) || null;
          let currentime = Date.now();

          if (this.valid(attemp) && this.valid(lasttime) && attemp <= 2) {
            if (currentime - lasttime >= timecheck[attemp > 2 ? 2 : attemp]) {
              let res = await this.requestForCheckIP();

              if (!res) {
                this.setCookie({
                  key: "iamempl",
                  value: `none:${currentime}:${attemp + 1}`,
                });
                return false;
              }

              this.setCookie({ key: "iamempl", value: `${res}` });
              return true;
            }
          }

          return false;
        }
      }
      if (!iamemp) {
        let res = await this.requestForCheckIP();

        if (!res) {
          this.setCookie({
            key: "iamempl",
            value: `none:${Date.now()}:1`,
          });
          return false;
        }

        this.setCookie({ key: "iamempl", value: `${res}` });
        return true;
      }

      return false;
    },

    async checkWidgetEventAndSendEvent() {
      if (
        this.valid(this.data["widget-event"]) &&
        this.valid(this.data["sessionId"])
      ) {
        let pushObj = {
          ssId: this.data["sessionId"],
          event_id: this.data["widget-event"],
          eventData: this.valid(this.data["widget-event-data"])
            ? this.data["widget-event-data"]
            : "",
        };

        const isBlock = await this.checkIP();

        if (isBlock) return;

        const roomExist = await firebase
          .database()
          .ref(`chatrooms_v2/${this.data["sessionId"]}`)
          .once("value")
          .then((snap) => snap.exists());

        if (roomExist) {
          firebase.database().ref("widget_events").push(pushObj);
        }
      }
    },

    async init() {
      this.data = await this.getQueryParamsFromURL();
      const handleFileTypes = ["pdf", "video", "file", "img"];

      if (!handleFileTypes.includes(this.data.filetype)) {
        this.isSuccess = false;
        this.isLoading = false;
        return;
      }

      this.checkWidgetEventAndSendEvent();

      const filePath = this.generateReferenceToFile();
      const [
        fileURL,
        success,
      ] = await this.downloadFileFromStorageAndGetFileURL(filePath);

      this.fileURL = fileURL;
      this.isSuccess = success;

      if(this.data.version) {
        this.fileName = this.data.filename;
      }
      
      else {
        this.fileName = `${this.data.filename}${
        this.valid(this.data.fileextension) ? `.${this.data.fileextension}` : ""
      }`;

      }

      this.isLoading = false;

      // if(!handleFileTypes.includes(data.filetype))
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
