<template>
    <div style="max-width: 95vw;height:95vh;display: flex;justify-content: center; align-items: center;">
        <img  :src="url"  style="
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
" />
    </div>
</template>

<script>

    export default {
    props: {
        url: String
    },
}
</script>