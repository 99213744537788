<template>
    <div>
      <vue-pdf-embed :width="width" :page="currentPage" :source="url" @loaded="pdfLoaded" />

    <div v-if="isLoading" style="height: 85vh;display: flex; justify-content: center; align-items: center;">
        <h3 style="margin: 10px;">Loading PDF</h3>
        <LoadSvg/>
    </div>

  
      <div class="navigation" v-if="totalPages && !isLoading">
        <button @click="goToPreviousPage" :disabled="currentPage <= 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="goToNextPage" :disabled="currentPage >= totalPages">Next</button>
      </div>

      <div v-if="!isLoading" style="margin-top: 20px;margin-bottom: 20px;">
        <DownloadButton :url="url"/>
      </div>
    </div>
  </template>
  
  <script>
    import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
    import LoadSvg from './LoadSvg.vue';
    import DownloadButton from './DownloadButton.vue';
  
  export default {
    components: {
    VuePdfEmbed,
    LoadSvg,
    DownloadButton
    },
    props: {
      url: String
    },
    data() {
      return {
        currentPage: 1,
        totalPages: null,
        isLoading: true,
        width: 1000
      };
    },
    created() {
        this.width = window.innerWidth - 100;
    },
    methods: {
      goToPreviousPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      goToNextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      pdfLoaded(data) {
        this.totalPages = data._pdfInfo.numPages;

        this.isLoading = false;
      }
    }
  }
  </script>
  
  
<style scoped>
.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  color: #666;
}

button:hover:not(:disabled) {
  background-color: #45a049;
}
</style>

  