<template>
    <a :href="url" download="">Download</a>
  </template>
  
  <script>
  export default {
    props: {
      url: {
        type: String,
        required: true
      },
      filename: {
        type: String,
        default: 'download'
      }
    },
  }
  </script>
  
  <style scoped>
  /* Style your button here */
a {

    /* Example styles */
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
  }
  </style>
  