import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/database'
import 'firebase/firestore'


export function getConfig() {
  return {
      apiKey: "AIzaSyA0r-42wNtIGwwUAhcCgxLFefmSBfpFpBg",
      authDomain: "car-appointment-fsqahl.firebaseapp.com",
      databaseURL: "https://car-appointment-fsqahl.firebaseio.com",
      projectId: "car-appointment-fsqahl",
      storageBucket: "car-appointment-fsqahl.appspot.com",
      messagingSenderId: "1005414428637",
      appId: "1:1005414428637:web:81ef9f3fc51aab02c51034"
    }
}


 const firebaseConfig = getConfig();


export const projectID = `${firebaseConfig.projectId}`;

firebase.initializeApp(firebaseConfig);

export default firebase;