<template>
    <div style="height: 85vh;display: flex;flex-direction: column; justify-content: center; align-items: center;">
        <h3>{{ fileName }}</h3>
        <div style="margin-top: 20px; margin-bottom: 20px;">
            <DownloadButton :url="url"/>
        </div>
    </div>
</template>

<script>
import DownloadButton from './DownloadButton.vue';

    export default {
    props: {
        url: String,
        fileName: String
    },
    components: { DownloadButton }
}

</script>